'use client';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, split, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { sloLogoutEndpoint, sloLogoutKey, sloAwsRegion } from 'helpers/utils/environment';

const makeClient = () => {
  const url = `${sloLogoutEndpoint}`;
  const region = `${sloAwsRegion}`;

  const auth: AuthOptions = {
    type: 'API_KEY',
    apiKey: `${sloLogoutKey}`,
  };

  const httpLink = new HttpLink({
    uri: `${sloLogoutEndpoint}`,
    fetchOptions: { cache: 'no-store' },
  });

  const AppSyncAuth = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    AppSyncAuth,
    httpLink,
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

export const SloSubscriptionApolloWrapper = ({ children }: React.PropsWithChildren) => {
  return <ApolloProvider client={makeClient()}>{children}</ApolloProvider>;
};
