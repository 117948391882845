import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/frontend/frontend/components/datadog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/frontend/frontend/components/launch-darkly/reackt-client-sdk/ldProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SloSubscriptionApolloWrapper"] */ "/opt/build/repo/packages/frontend/frontend/helpers/padi/slo/subscription/apolloWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/next/font/google/target.css?{\"path\":\"fonts/index.ts\",\"import\":\"Noto_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-noto-sans\",\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/frontend/frontend/styles/app.css");
