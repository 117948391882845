'use client';

import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { serverEnvName } from 'helpers/utils/environment';

const InitDataDog = () => {
  useEffect(() => {
    const initDD = () => {
      // Custom logs.
      datadogLogs.init({
        env: serverEnvName,
        site: 'datadoghq.com',
        clientToken: 'pub4d25321d9dc52ac5a25c27feb2d9890b',
        datacenter: 'us',
        // show log on the localhost
        // forwardConsoleLogs: ["error", "info"],
        forwardErrorsToLogs: false,
        sessionSampleRate: 100,
        service: 'ct-frontend',
      });
      // RUM.
      datadogRum.init({
        applicationId: 'fb92dc04-9ab9-47aa-9633-c0579c941cde',
        clientToken: 'pub0a485677110a2530c39b96f850f836e8',
        site: 'datadoghq.com',
        service: 'https://store.padi.com',
        env: serverEnvName,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        defaultPrivacyLevel: 'mask-user-input',
      });
    };
    initDD();
  }, []);

  return <></>;
};

export default InitDataDog;
